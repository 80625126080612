// auth.js

import { defineStore } from "pinia";
//import { useStorage } from "@vueuse/core";
import trainingsService from "../services/trainings.service";

export const useTrainingsStore = defineStore("trainings", {
  actions: {
    /**
     * Getter to retrieve the training programs
     * @returns {Promise<Object>} A promise that resolves to the training programs.
     */
    async getTrainings() {
      const response = await trainingsService.getTrainings();
      return response;
    },
    /**
     * Getter to retrieve the threat categories
     * @returns {Promise<Object>} A promise that resolves to the threat categories.
     */
    async getTrainingCategories() {
      const response = await trainingsService.getTrainingCategories();
      return response;
    },
    /**
     * Getter to retrieve the training categories
     * @returns {Promise<Object>} A promise that resolves to the threat categories.
     */
    async getJobProfilesByTrainingCategories() {
      const response =
        await trainingsService.getJobProfilesByTrainingCategories();
      return response;
    },
  },
});
