<template>
  <div class="bg-primary py-20">
    <h1 class="text-white text-5xl">Datenschutzerklärung ITS.Kompetent</h1>
  </div>
  <div class="bg-white py-20">
    <div class="lg:container lg:mx-auto text-left text-primary">
      <p><strong>Deutsch:</strong></p>
      <br />
      <p>Please find the english version below.</p>
      <br />
      <p>
        <strong
          >§ 1 Information über die Erhebung personenbezogener Daten</strong
        >
        <br />(1) Im Folgenden informieren wir über die Erhebung
        personenbezogener Daten bei Nutzung unserer Website. Personenbezogene
        Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B.
        Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
        <br />
        <br />(2) Verantwortlicher gem. Art. 4 Abs. 7
        EU-Datenschutz-Grundverordnung (DS-GV0) ist die
        <br />Georg-August-Universität Göttingen <br />Der Präsident <br />Prof
        Dr. Metin Tolan <br />Wilhelmsplatz 1 <br />37073 Göttingen <br />Tel.
        0551 39-21000 <br />E-Mail:
        <a class="text-blue-400" href="mailto:praesident@uni-goettingen.de"
          >praesident@uni-goettingen.de</a
        >
        <br />
        <br />Unseren Datenschutzbeauftragten Prof. Dr. Andreas Wiebe erreichen
        Sie unter der Adresse “Platz der Göttinger Sieben 6, 37073 Göttingen”,
        Tel. 0551 39 – 2 7381, E-Mail: datenschutz@uni-goettingen.de
        <br />
        <br />(3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
        Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
        E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
        gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang
        anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
        erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche
        Aufbewahrungspflichten bestehen.
        <br />
        <br />(4) Falls wir für einzelne Funktionen unseres Angebots auf
        beauftragte Dienstleister zurückgreifen, werden wir Sie untenstehend im
        Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch
        die festgelegten Kriterien der Speicherdauer.
        <br />
        <br />
        <br /><strong>§ 2 Ihre Rechte</strong> <br />(1) Sie haben gegenüber uns
        folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen
        Daten:
      </p>

      <ul>
        <li>Recht auf Auskunft,</li>
        <li>Recht auf Berichtigung oder Löschung,</li>
        <li>Recht auf Einschränkung der Verarbeitung,</li>
        <li>Recht auf Widerspruch gegen die Verarbeitung.</li>
        <li>
          Ein Recht auf Datenübertragbarkeit existiert im
          öffentlichen-rechtlichen Bereich nicht. (Artikel 20, Abs. 3, S. 2)
        </li>
      </ul>

      <p>
        <br />(2) Sie haben zudem das Recht, sich bei einer
        Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
        personenbezogenen Daten durch uns zu beschweren. Sie dürfen sich mit
        Beschwerden auch an die Landesbeauftragte für den Datenschutz
        Niedersachsen, Prinzenstraße 5, 30159 Hannover, Tel. 0511/120-4500,
        poststelle@lfd.niedersachsen.de wenden.
        <br />
        <br />
        <br /><strong
          >§ 3 Erhebung personenbezogener Daten bei Besuch unserer
          Website</strong
        >
        <br />(1) Bei der bloß informatorischen Nutzung der Website, also wenn
        Sie sich nicht registrieren oder uns anderweitig Informationen
        übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
        Browser an unseren Server übermittelt. Wenn Sie unsere Website
        betrachten möchten, erheben wir die folgenden Daten, die für uns
        technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die
        Stabilität und Sicherheit zu gewährleisten.
      </p>

      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Website, von der die Anforderung kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfläche</li>
        <li>Sprache und Version der Browsersoftware.</li>
      </ul>
      <br />
      <p>
        (2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
        unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies
        handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von
        Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche
        der Stelle, die den Cookie setzt (hier durch uns), bestimmte
        Informationen zufließen. Cookies können keine Programme ausführen oder
        Viren auf Ihren Computer übertragen. Sie dienen dazu, das
        Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.
        <br />
        <br />(3) Einsatz von Cookies: <br />a) Diese Website nutzt folgende
        Arten von Cookies, deren Umfang und Funktionsweise im Folgenden
        erläutert werden:
      </p>

      <ul>
        <li>Transiente Cookies (dazu b)</li>
        <li>Persistente Cookies (dazu c).</li>
      </ul>

      <p>
        b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den
        Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese
        speichern eine sogenannte Session-ID, mit welcher sich verschiedene
        Anfra-gen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen.
        Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere
        Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich
        ausloggen oder den Browser schließen. <br />c) Persistente Cookies
        werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je
        nach Cookie unterscheiden kann. Sie können die Cookies in den
        Sicherheitseinstellungen Ihres Browsers jederzeit löschen. <br />d) Sie
        können Ihre Browser-Einstellung entsprechend Ihren Wünschen
        konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen
        Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht
        alle Funktionen dieser Website nutzen können. <br />e) Wir setzen
        Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie
        über einen Account bei uns verfügen. Andernfalls müssten Sie sich für
        jeden Besuch erneut einloggen. <br />f) Die genutzten Flash-Cookies
        werden nicht durch Ihren Browser erfasst, sondern durch Ihr
        Flash-Plug-in. Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem
        Endgerät abgelegt werden. Diese Objekte speichern die erforderlichen
        Daten unabhängig von Ihrem verwendeten Browser und haben kein
        automatisches Ablaufdatum. Wenn Sie keine Verarbeitung der Flash-Cookies
        wünschen, müssen Sie ein entsprechendes Add-On installieren, z. B.
        „Clear Flash Cookies“ für Mozilla Firefox (
        <a
          rel="noreferrer noopener"
          href="https://addons.mozilla.org/en-US/firefox/addon/clear-flash-cookies/"
          target="_blank"
          >https://addons.mozilla.org/en-US/firefox/addon/clear-flash-cookies/</a
        >) oder Sie verwenden Google Chrome, welches das Löschen von Flash
        Cookies unterstützt. Die Nutzung von HTML5 storage objects können Sie
        verhindern, indem Sie in Ihrem Browser den privaten Modus einsetzen.
        Zudem empfehlen wir, regelmäßig Ihre Cookies und den Browser-Verlauf
        manuell zu löschen.
        <br />
        <br />(4) Wenn externe Inhalte auf einer universitären Internetseite
        (per iframe oder auf ähnliche Weise) eingebettet werden, können Cookies
        gesetzt bzw. generelle Rahmendaten wie Ihre IP-Adresse an den jeweiligen
        Anbieter übertragen werden. Es gelten somit ebenfalls die
        Datenschutzrichtlinien des jeweiligen Anbieters. Aus diesem Grund sind
        die auf unseren Seiten eingebetteten Inhalte von Twitter und Youtube
        standardmäßig inaktiv und werden erst durch Ihre Zustimmung zu den
        Datenschutzbestimmungen des externen Dienstleisters aktiviert. Die
        Zustimmung wird gespeichert und kann auf folgender Seite wieder
        zurückgenommen werden:
      </p>
      <br />
      <p>
        (5) Diese Website verwendet zur Webanalyse Matomo. Hierzu werden durch
        „Cookies“, Textdateien, erzeugte Nutzungsinformationen (einschließlich
        Ihrer gekürzten IP-Adresse) an den Server übertragen und zu
        Nutzungsanalysezwecken gespeichert, nachdem sie anonymisiert wurden. Die
        durch das Cookie erzeugten Informationen über Ihre Benutzung der Seite
        werden nicht an Dritte weitergegeben.
      </p>
      <iframe
        frameborder="no"
        width="500px"
        height="250px"
        src="https://api.itskompetent.uni-goettingen.de/matomo/index.php?module=CoreAdminHome&action=optOut&language=de"
      ></iframe>

      <br />
      <p><strong>§ 4 Zweck und Rechtsgrundlage der Verarbeitung</strong></p>

      <p>
        Die Erhebung Ihrer Daten erfolgt gemäß Art. 6 Abs. 1 UAbs. 1 lit. e
        DSGVO, §§ 3 Abs. 1 S. 1 Nr. 1 NHG, 13 Abs. 1 S. 1 NDSG. Der Betreiber
        erhebt Ihre Daten dabei für die Wahrnehmung einer Aufgabe, die im
        öffentlichen Interesse liegt. Das öffentliche Interesse besteht hierbei
        in den Forschungstätigkeiten des Betreibers. Zweck der Erhebung ist das
        Erlangen eines Einblicks in Personengruppen, die ein Interesse an den
        Forschungsarbeiten des Betreibers haben was wiederum ggf. Implikationen
        für das Forschungsvorhaben liefert.
      </p>

      <br />
      <p><strong>§ 5 Datenweitergabe an Dritte</strong></p>

      <p>Der Betreiber gibt keine der erhobenen Daten an Dritte weiter.</p>
      <br />
      <p>
        <strong>§ 6 Speicherfrist der erhobenen</strong
        ><em><strong> Daten</strong></em>
      </p>

      <p>
        Die erhobenen Daten werden 365 Tage aufbewahrt und anschließend
        automatisch gelöscht.
      </p>
      <br />
      <p>
        <strong>Kontaktformulare</strong>
        <br />
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert und verarbeitet. Ihre Daten werden
        ausschließlich zweckgebunden zur Beantwortung und Bearbeitung Ihrer
        Frage genutzt. Die Datenverarbeitung erfolgt hier nach Art. 6 Abs. S. 1
        lit. f DSGVO auf Grundlage des berechtigten Interesses.Ihre Daten werden
        nach abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der
        Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
        Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen
        Aufbewahrungspflichten entgegenstehen.
      </p>

      <br />

      <p><strong>English:</strong></p>
      <br />

      <p>
        <strong
          >Article 1: Information on the Collection of Personal Data
        </strong>
        <br />(1) In the following, we will provide information regarding the
        personal data that is collected when using our website. “Personal Data”
        is defined as any information which refers to you personally, such as
        name, address, email addresses or user behaviour.
      </p>

      <p>
        (2) In accordance with Article 4(7) of the EU General Data Protection
        Regulation (GDPR), the Controller is
        <br />Georg-August-Universität Göttingen <br />The President <br />Prof
        Dr. Metin Tolan <br />Wilhelmsplatz 1 <br />37073 Göttingen, Germany
        <br />Phone +49 (0)551 39-21000 <br />Email:
        <a class="text-blue-400" href="mailto:praesident@uni-goettingen.de"
          >praesident@uni-goettingen.de</a
        >
      </p>
      <br />
      <p>
        Our Data Protection Officer, Prof. Andreas Wiebe, can be reached at the
        address “Platz der Göttinger Sieben 6, 37073 Göttingen, Germany”, Phone
        +49 (0)551 39-2 7381, email: datenschutz@uni-goettingen.de
      </p>
      <br />
      <p>
        (3) When contacting us via email or a contact form, we will use the
        personal data (your email address, in some cases your name and phone
        number as well) that you provide to us in order to answer your
        questions. We delete case-related data when storage is no longer
        necessary and restrict processing given statutory storage obligations.
      </p>
      <br />
      <p>
        (4) If we rely on contracted providers for individual functions of our
        services, we will inform you in detail about the particular processes
        below. In doing so, we will also mention the defined criteria pertaining
        to the storage period.
      </p>
      <br />
      <p>
        <strong>Article 2: Your Rights</strong> <br />(1) You have the following
        rights regarding our use of your personal data: <br />Right of access,
        <br />Right to correction or erasure, <br />Right to restriction of
        processing, <br />Right to object to the processing. <br />The right to
        data portability does not exist in the public-law sector. (Article 20
        (3) sentence no. 2)
      </p>
      <br />
      <p>
        (2) You also have the right to lodge a complaint with a data protection
        supervisory authority about our processing of your personal data. If you
        wish to file a complaint, you may also contact the State Data Protection
        Authority for Lower Saxony, located at Prinzenstrasse 5, 30159 Hannover,
        Germany, phone number +49 (0)511/120-4500,
        poststelle@lfd.niedersachsen.de.
      </p>
      <br />
      <p>
        <strong
          >Article 3: Collection of Personal Data when Using our Website</strong
        >
      </p>

      <p>
        (1) When using the website for informational purposes only, i.e., if you
        do not register or otherwise provide us with information, we collect
        only that personal data which your browser transmits to our server. If
        you wish to view our website, we collect the following data to the
        extent that it is technically necessary for us to display our website to
        you and ensure its stability and security. <br />&gt; IP address
        <br />&gt; Date and time of request <br />&gt; Time zone difference to
        Greenwich Mean Time (GMT) <br />&gt; Content of request (specific page)
        <br />&gt; Access status/HTTP- status code <br />&gt; Data volume
        transmitted in each case <br />&gt; The website the request has come
        from browser <br />&gt; Operating system and its interface <br />&gt;
        Language and browser software version.
      </p>
      <br />
      <p>
        (2) In addition to the aforementioned data, cookies are stored on your
        computer when you use our website. Cookies are small text files
        attributed to the browser you are using and stored on your hard drive
        and provide specific information to the website that places the cookie
        (in this case, our website). Cookies cannot be used to run programmes or
        deliver viruses to your computer. Overall, they serve to make Internet
        content more user-friendly and effective.
      </p>
      <br />
      <p>
        (3) Use of cookies: <br />a) This website uses the following types of
        cookies, the extent and operating principle of which will be explained
        hereinafter: Transient cookies (see b) Persistent cookies (see c).
        <br />b) Transient cookies are automatically deleted when you close your
        browser. In particular, these include session cookies. These cookies
        store a session ID, which is used to assign various requests from your
        browser to a common session. This allows your computer to be recognised
        when you return to our website. The session cookies are removed once you
        log out or close your browser. <br />c) Persistent cookies are
        automatically deleted after a specified period, which may vary depending
        on the cookie. You can delete cookies at any time in the security
        settings of your browser. <br />d) You can configure your browser
        settings according to your wishes and, for example, refuse to accept
        third-party cookies or all cookies. Please be aware that you may not be
        able to use all features of this website if you refuse to accept
        cookies. <br />e) Given that you have a user account, we use cookies to
        identify you on subsequent visits. Otherwise, you would have to enter
        your login information every time you visit our website. <br />f) The
        Flash cookies used are not recorded by your browser, but by your Flash
        plug-in. We also use HTML5 storage objects that are stored on your
        device. These objects store the required data regardless of the browser
        you are using, and they do not have an automatic expiry date. If you do
        not wish the Flash cookies to be processed, you must install an
        appropriate add-on, e.g. “Clear Flash Cookies” for Mozilla Firefox
        (https://addons.mozilla.org/en-US/firefox/addon/clear-flash-cookies/) or
        use Google Chrome, which supports the deletion of flash cookies. You can
        prevent the use of HTML5 storage objects by using “Private Mode”, which
        is available in all modern browsers. In addition, we recommend that you
        regularly delete your cookies and browser history manually.
      </p>
      <br />
      <p>
        (4) If external content is embedded on a university website (via iframe
        or in a similar way), cookies may be set or general framework data such
        as your IP address may be transmitted to the respective provider. Thus,
        the privacy policies of the respective provider also apply. For this
        reason, the Twitter and YouTube content embedded on our pages is
        inactive by default and is only activated by your consent to the privacy
        policy of the external service provider.
      </p>
      <br />
      <p>
        (5) This website uses Matomo for web analytics. For this purpose, the
        usage information generated by “cookies” (including your shortened IP
        address) is transmitted to the server and stored for usage analysis
        purposes after it has been made anonymous. The information generated by
        the cookie(s) about your usage of the site will not be transmitted to
        third parties.
      </p>
      <iframe
        frameborder="no"
        width="500px"
        height="250px"
        src="https://api.itskompetent.uni-goettingen.de/matomo/index.php?module=CoreAdminHome&action=optOut&language=en"
      ></iframe>

      <br />
      <p>
        <strong>Article 4: Purpose and legal basis of data processing</strong>
      </p>

      <p>
        The collection of your data is carried out in accordance with Article 6
        (1.1e) of the EU General Data Protection Regulation. The operator
        collects your data for the performance of a task that is in the public
        interest. The public interest consists in the research activities of the
        operator. The purpose of the collection is to gain insight into groups
        of persons who have an interest in the research work of the operator
        which might provide implications for the research project.
      </p>
      <br />
      <p><strong>Article 5: Passing on data to third parties</strong></p>

      <p>
        The operator does not pass on any of the collected data to third
        parties.
      </p>
      <br />
      <p><strong>Article 6: Storage period of the collected data</strong></p>

      <p>
        The collected data will be stored for 365 days and will be automatically
        deleted afterwards.
      </p>
      <p>
        <strong>Contact Forms</strong>
        <br />
        If you send us inquiries via the contact form, your details from the
        inquiry form, including the contact data you provide there, will be
        stored and processed by us for the purpose of processing the inquiry and
        in the event of follow-up questions. Your data will be used exclusively
        for the purpose of answering and processing your question. The data
        processing takes place here in accordance with Art. 6 para. 1 lit. f
        GDPR on the basis of legitimate interest.Your data will be deleted after
        final processing of your request. This is the case when it can be
        inferred from the circumstances that the matter in question has been
        conclusively clarified and provided that there are no statutory
        retention obligations to the contrary.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackInfoComponent",

  computed: {
    doNotTrackEnabled() {
      return navigator.doNotTrack === "1" || navigator.doNotTrack === "yes";
    },
  },
  created() {
    this.updateTrackingPreference();
  },

  methods: {
    updateTrackingPreference() {
      if (this.doNotTrackEnabled) {
        console.log("Tracking is disabled per browser setting.");
        // Additional logic to disable tracking scripts
      } else {
        console.log("Tracking is enabled.");
        // Additional logic to enable tracking scripts
      }
    },
  },
};
</script>
